// LatestNewsCards.js

import React, { useEffect } from 'react';
import '../Css/LatestNewsCards.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const LatestNewsCards = () => {
  const newsItems = [
    {
      id: 1,
      title: 'A well-designed dashboard enhances decision-making',
      content: 'A software dashboard panel serves as a central interface for A well-designed dashboard enhances decision-making and streamlines operational efficiency',
      date: '2024-02-24',
      image: 'White lebel dashboard png .png',
    },
    {
      id: 2,
      title: 'Languages like C and C++ can be used for backend logic and data processing.',
      content: 'A software dashboard can be built using various programming languages and technologies. HTML and CSS provide the structure and styling, while JavaScript enables interactivity and dynamic content.languages like C and C++ can be used for backend logic and data processing.',
      date: '2024-02-25',
      image: 'meeting.jpg',
    },
    {
      id: 3,
      title: 'Experience by providing consistent functionality regardless of screen size',
      content: 'A software panel should be responsive, adapting seamlessly to desktop, tablet, and mobile views. It utilizes flexible layouts and scalable elements, ensuring optimal usability and accessibility across devices. This approach enhances users..',
      date: '2024-02-25',
      image: 'software2.png',
    },
    
  ];

  // useEffect(() => {
    const handleNewsLoader = () => {
      // GSAP animations for the news cards container
      gsap.from('.latest-news-cards-container', { opacity: 0, y: -50, duration: 0.5, delay: 0.5, scrollTrigger: { trigger: '.latest-news-cards-container', start: 'top 80%' } });

      // GSAP animations for the news cards on hover
      const newsCards = document.querySelectorAll('.news-card');
      // newsCards.forEach((card) => {
      //   card.addEventListener('mouseenter', () => {
      //     gsap.to(card, { scale: 1.05, duration: 0.3 });
      //   });

      //   card.addEventListener('mouseleave', () => {
      //     gsap.to(card, { scale: 1, duration: 0.3 });
      //   });
      // });
    };

  //   // Add event listener for the load event
  //   window.addEventListener('load', handleNewsLoader);

  //   // Cleanup function to remove the event listener
  //   return () => {
  //     // Remove event listener when the component unmounts
  //     window.removeEventListener('load', handleNewsLoader);
  //   };
  // }, []); // Empty dependency array to run the effect only once

  return (
    <div className="latest-news-cards-container ">
      <h2>Latest News</h2>
      <div className="newscards-wrapper">
        {newsItems.map((news) => (
          <div className="news-card shadow-2xl shadow-slate-900 hover:shadow-blue-800" key={news.id}>
            <img src={news.image} alt={news.title} />
            <div className="newscard-content">
              <h3>{news.title}</h3>
              <p className="newscontent">{news.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LatestNewsCards;