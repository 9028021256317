import React from 'react'
import Header from '../Components/Header';
import Top from '../Components/Top';
import Feature from '../Components/Feature';
import Phone from '../Components/Phone';
import MovingTextComponent from '../Components/MovingTextComponent';
import ColumnsWithIcons from '../Components/ColumnsWithIcons';
import TwoColumnLayout from '../Components/TwoColumnLayout';
import Faqs from '../Components/Faqs';
import Second from '../Components/Second';
import WhiteColumn from '../Components/WhiteColumn';
import Layout from '../Components/Layout';
import QAndA from '../Components/QAndA';
import LatestNewsCards from '../Components/LatestNewsCards';
import Platform from '../Components/Platform';
import Fast from '../Components/Fast';
import Bslide from '../Components/Bslide';
import Steps from '../Components/Steps';
import Pricing from '../Components/Pricing';
import Special from '../Components/Special';
import SpecialNew from '../Components/SpecialNew';
import Additional from '../Components/Additional';
import Different from '../Components/Different';
import ProductsUndermobile from '../Components/ProductsUndermobile';
import Experience from '../Components/Experience';
const Home = () => {
  return (
    <div className='home-container'>
        <Header/>
        <Top />
        {/* <Feature /> */}
        <ColumnsWithIcons />
        <Additional/>
        <Special/>
        <SpecialNew/>
        {/* <Different/> */}
        <Phone />
        <div className='relative py-5 my-5'>
        <ProductsUndermobile />
        <Experience/>
        <div className="absolute overflow-hidden w-full h-full top-0 left-0">
          <img src="/tttwinkle.svg" alt="" />
        </div>
        </div>
{/*         
        <MovingTextComponent />
        
        <TwoColumnLayout /> */}
        
        {/* <Faqs /> */}
        {/* <QAndA /> */}
        <LatestNewsCards />
        {/* <Platform /> */}
        {/* <Fast /> */}
        {/* <Bslide /> */}
        <Steps />
        <Pricing /> 
        
    </div>
  )
}

export default Home