import React from 'react'
import LineDot from './LineDot';
import '../Css/Special.css';
import Special3 from './Special3';
// import { AnimatedBeamDemo } from './AnimatedBeamDemo.tsx';
// import { AnimatedBeam } from './magicui/animated-beam.tsx';

const Special = () => {
  return (
    <div className='container-special'>
        <h1 className="spec-text" style={{fontSize:'2em',borderRadius:'5px', backgroundColor:'#0202a7',color:'white', width:'100%'}}>How, we are different from others.</h1>
        {/* <LineDot /> */}
        {/* <AnimatedBeamDemo/> */}
        {/* <AnimatedBeam/> */}
        <Special3/>
    </div>
  )
}

export default Special