import React from 'react'
import '../Css2/Aboutus.css'; // Import your CSS file for Footer styling
import Faqs from '../Components/Faqs';

const Aboutus = () => {
  return (
    <div style={{ marginTop: '100px' }}>
      <div className="awards_and_recognition py-3 mt-5">
        <h1 className="shine">AWARDS AND RECOGNITION</h1>
        <div className="row justify-content-center">
          <div className="col-md-3">
            <div className="first_card m-1">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGN7CAFuv5uq2lPmgU9VCtHpYwIn4EHTFrrg&s" alt="" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="first_card m-1">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGN7CAFuv5uq2lPmgU9VCtHpYwIn4EHTFrrg&s" alt="" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="first_card m-1">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGN7CAFuv5uq2lPmgU9VCtHpYwIn4EHTFrrg&s" alt="" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="first_card m-1">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGN7CAFuv5uq2lPmgU9VCtHpYwIn4EHTFrrg&s" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className='aboutus-container'>
        <div className='left-aboutus'>
          <img className='' src="team.png" alt="Logo" />
        </div>

        <div className='right-aboutus px-0' style={{
          borderRadius: '25px',
          background: '#0000ff57',
        }}>
          <h1 className="ty shine">About IDSPay</h1>
          <p className='my-0' style={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: '18px',
            background: '#e48529ab',
            padding: '10px',
            borderRadius: '25px',
          }}>
            INFOCART GROUP
            This INFOCART GROUP is a group of companies having company like IDSHAAT ECOMMERCE AND SERVICES PRIVATE LIMITED by Idshaat Ecommerce And Services Private Limited. Idshaat Ecommerce And Services Private Limited is a Private incorporated on 18 January 2019. It is classified as Non-govt company and is registered at Registrar of Companies, Kanpur. Its authorized share capital is Rs. 1,000,000 and its paid up capital is Rs. 1,000,000. It is inolved in Business activities n.e.c.
            Idshaat Ecommerce And Services Private Limited's Annual General Meeting (AGM) was last held on 30 November 2021 and as per records from Ministry of Corporate Affairs (MCA), its balance sheet was last filed on 31 March 2021.
            Directors of Idshaat Ecommerce And Services Private Limited are Prakash Kumar, Ravind Prasad and .
            Idshaat Ecommerce And Services Private Limited's Corporate Identification Number is (CIN) U74999UP2019PTC112555 and its registration number is 112555.Its Email address is prakashkr2081@gmail.com and its registered address is C-339, ALPHA-1 GREATER NOIDA GAUTAM BUDH NAGAR Gautam Buddha Nagar UP 201310 IN .</p>

        </div>


      </div>
      {/* <Faqs/> */}
      <div className="mission_vission_core_value d-none">
        <h1 className="text-center shine">Mission | Vission | Core Value</h1>
        <div className="row nowrap misson_row">
          <div className="col-md-4">
            <div className="m-4">
              <img src="team.png" alt="" className="w-full h-auto" />
              <h3 className="shine text-center">Mission</h3>
              <p className="text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam officia doloremque suscipit minus quibusdam excepturi accusantium eveniet quos incidunt delectus. Amet quasi earum fuga nisi reiciendis dolores similique quod officia, explicabo repellendus ex velit cum consequatur. Itaque fugit ratione quibusdam suscipit enim beatae consequuntur accusamus, est nobis. Aspernatur.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="m-4">
              <img src="team.png" alt="" className="w-full h-auto" />
              <h3 className="shine text-center">Vision</h3>
              <p className="text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam officia doloremque suscipit minus quibusdam excepturi accusantium eveniet quos incidunt delectus. Amet quasi earum fuga nisi reiciendis dolores similique quod officia, explicabo repellendus ex velit cum consequatur. Itaque fugit ratione quibusdam suscipit enim beatae consequuntur accusamus, est nobis. Aspernatur.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="m-4">
              <img src="team.png" alt="" className="w-full h-auto" />
              <h3 className="shine text-center">Core Value</h3>
              <p className="text-justify">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam officia doloremque suscipit minus quibusdam excepturi accusantium eveniet quos incidunt delectus. Amet quasi earum fuga nisi reiciendis dolores similique quod officia, explicabo repellendus ex velit cum consequatur. Itaque fugit ratione quibusdam suscipit enim beatae consequuntur accusamus, est nobis. Aspernatur.</p>
            </div>
          </div>
        </div>
      </div>


      <div className="mission2">
      <h1 className="text-center shine">Mission | Vission | Core Value</h1>
       <div className="row  w-100">
       <div className="col-md-4">
          <div className="container2 m-3">
          <div className="wrapper2">
            <div className="banner-image2"><img src="mission.png" className='d-block w-16 mx-auto' alt="" /></div>
            {/* <h1> Toyota Supra</h1> */}
            
            <h1 className="shine text-center">Mission</h1>
            <p className='p'>Lorem ipsum dolor sit amet, <br />
              consectetur adipiscing elit.</p>
          </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="container2 m-3">
          <div className="wrapper2">
            <div className="banner-image2"> <img className='mx-auto d-block' src="vision.png" alt="" /></div>
            {/* <h1> Toyota Supra</h1> */}
            
            <h1 className="shine text-center">Vision</h1>
            <p className='p'>Lorem ipsum dolor sit amet, <br />
              consectetur adipiscing elit.</p>
          </div>
          </div>
        </div>
        <div className="col-md-4">
            
        <div className="container2 m-3">
          <div className="wrapper2">
            <div className="banner-image2"> <img src="core_value.png" className='d-block mx-auto w-16' alt="" /></div>
            {/* <h1> Toyota Supra</h1> */}
            
            <h1 className="shine text-center">Core Value</h1>
            <p className='p'>Lorem ipsum dolor sit amet, <br />
              consectetur adipiscing elit.</p>
          </div>
        </div>
        </div>
       </div>
      </div>


      <div className="meet_our_team relative">
        <h1 className="text-center shine mb-0 pb-0">Meet our Team</h1>
        <p className="text-center teer_nishan teer_nishan1"></p>
        <p className="text-center teer_nishan teer_nishan2"></p>
        <div className="row teer_nishan_top_box nowrap">
          <div className="col-md-3">
            <div className="profile_card m-3 p-3 d-flex justify-center flex-col items-center">
              <img src="6.jpg" className='w-full' alt="" />
              <h3 className="text-center my-2 text-light">EMPLOYEE NAME</h3>
              <p className="text-center text-light">Designation</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="profile_card m-3 p-3 d-flex justify-center flex-col items-center">
              <img src="6.jpg" className='w-full' alt="" />
              <h3 className="text-center my-2 text-light">EMPLOYEE NAME</h3>
              <p className="text-center text-light">Designation</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="profile_card m-3 p-3 d-flex justify-center flex-col items-center">
              <img src="6.jpg" className='w-full' alt="" />
              <h3 className="text-center my-2 text-light">EMPLOYEE NAME</h3>
              <p className="text-center text-light">Designation</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="profile_card m-3 p-3 d-flex justify-center flex-col items-center">
              <img src="6.jpg" className='w-full' alt="" />
              <h3 className="text-center my-2 text-light">EMPLOYEE NAME</h3>
              <p className="text-center text-light">Designation</p>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default Aboutus;