import React, { useState, useEffect } from 'react';
import Captcha from './Captcha';
import ReCAPTCHA from 'react-google-recaptcha';

function MyModal() {
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [captcha, setCaptcha] = useState('');
  return (
    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">Schedule DEMO</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {/* Input fields */}
            <div className="mb-3">
              <input type="text" className="form-control border-primary" id="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="mb-3">
              <input type="text" className="form-control border-primary" id="mobile" placeholder="Mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} />
            </div>
            <div className="mb-3">
              <input type="email" className="form-control border-primary" id="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="mb-3">
              <input type="text" className="form-control border-primary" id="country" placeholder="Country" value={country} onChange={(e) => setCountry(e.target.value)} />
            </div>
            <div className="mb-3">
              <input type="text" className="form-control border-primary" id="state" placeholder="State" value={state} onChange={(e) => setState(e.target.value)} />
            </div>
            <div className='col-md-1'>
            <ReCAPTCHA  sitekey="6LfkJh0qAAAAAIgjIBt6jFqg4Hp8Jm7B4ea0LyyX" onChange={token => console.log(token)} />
            </div>
             {/* 6Lf1gQIqAAAAAGoHWwqDqEHnhNa3bRQyzWtZ40iB */}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary" >Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyModal;