import React from 'react'
import '../Css3/Productdrop.css';

const Productdrop = () => {
  return (
    <>
    <div className='product-drop-container'>
     

      <div className='left-product-droppage'>
      <h1 className='ty'>Cloud Hosting Services</h1>
        <p>
            INFOCART GROUP
            This INFOCART GROUP is a group of companies having company like IDSHAAT ECOMMERCE AND SERVICES PRIVATE LIMITED by Idshaat Ecommerce And Services Private Limited. Idshaat Ecommerce And Services Private Limited is a Private incorporated on 18 January 2019. It is classified as Non-govt company and is registered at Registrar of Companies, Kanpur. Its authorized share capital is Rs. 1,000,000 and its paid up capital is Rs. 1,000,000. It is inolved in Business activities n.e.c.
            Idshaat Ecommerce And Services Private Limited's Annual General Meeting (AGM) was last held on 30 November 2021 and as per records from Ministry of Corporate Affairs (MCA), its balance sheet was last filed on 31 March 2021.
            Directors of Idshaat Ecommerce And Services Private Limited are Prakash Kumar, Ravind Prasad and .
            Idshaat Ecommerce And Services Private Limited's Corporate Identification Number is (CIN) U74999UP2019PTC112555 and its registration number is 112555.Its Email address is prakashkr2081@gmail.com and its registered address is C-339, ALPHA-1 GREATER NOIDA GAUTAM BUDH NAGAR Gautam Buddha Nagar UP 201310 IN .</p>
        </div>

        <div className='right-product-droppage'>
        
        <img className='' src="clod.png" alt="Logo" />
        
                    
        </div>

        

    </div>

<div className='product-drop-container'>
     
     
<div className='left-product-droppage'>
<img className='' src="clod.png" alt="Logo" />
  </div>

  <div className='right-product-droppage'>
  <h1 className='ty'>Design & Development</h1>
 
  <p>
      INFOCART GROUP
      This INFOCART GROUP is a group of companies having company like IDSHAAT ECOMMERCE AND SERVICES PRIVATE LIMITED by Idshaat Ecommerce And Services Private Limited. Idshaat Ecommerce And Services Private Limited is a Private incorporated on 18 January 2019. It is classified as Non-govt company and is registered at Registrar of Companies, Kanpur. Its authorized share capital is Rs. 1,000,000 and its paid up capital is Rs. 1,000,000. It is inolved in Business activities n.e.c.
      Idshaat Ecommerce And Services Private Limited's Annual General Meeting (AGM) was last held on 30 November 2021 and as per records from Ministry of Corporate Affairs (MCA), its balance sheet was last filed on 31 March 2021.
      Directors of Idshaat Ecommerce And Services Private Limited are Prakash Kumar, Ravind Prasad and .
      Idshaat Ecommerce And Services Private Limited's Corporate Identification Number is (CIN) U74999UP2019PTC112555 and its registration number is 112555.Its Email address is prakashkr2081@gmail.com and its registered address is C-339, ALPHA-1 GREATER NOIDA GAUTAM BUDH NAGAR Gautam Buddha Nagar UP 201310 IN .</p>
  
              
  </div>

  

</div>
</>
  )
}

export default Productdrop