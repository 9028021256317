import React from 'react';
import '../Css/Phone.css';
import SliderComponent from './SliderComponent';

const Phone = () => {
  return (
    <>
      <div className='phone-container relative'>
        <div className='phonecenter-content absolute_full_width_outer'>
          <img src="/page.png" alt="Example Image" className="absolute_full_width" />
          <h3 className="z_top good_one_h3 mt-2 mx-2 mb-5">Mobile App Available.</h3>
          <div className="flex-gap-2 hidden">
            <div class="wrapper">
              <div class="bg"> Mobile </div>
              <div class="fg"> Mobile </div>
            </div>
            <div class="wrapper">
              <div class="bg"> App </div>
              <div class="fg"> App </div>
            </div>
            <div class="wrapper">
              <div class="bg"> Available </div>
              <div class="fg"> Available </div>
            </div>
          </div>
          <div className="container no_bg flex gap-28 new_one justify-between">
            <div className="flex flex-column">
              <div className="sm md:mx-auto sm:mx-auto  mx-auto out_side_mobile_view phonepic z_top">
                <div className="insde_mob_view">
                  <SliderComponent classvalue='mob_animate' speed_ns={1500} img1='/mob.jpg' img2='/mob2.jpg' img3='/mob3.jpg' img4='/mob4.jpg' img5='/mob5.jpg' img6='/mob6.jpg' widths='300px' heights='200px' fade_s={false} dots={false} />
                </div>
              </div>
              <h3 className="text-center font-bold">Customer App</h3>
            </div>
            <div className="flex flex-column">
              <div className="sm md:mx-auto sm:mx-auto  mx-auto out_side_mobile_view phonepic z_top">
                <div className="insde_mob_view">
                  <SliderComponent classvalue='mob_animate' speed_ns={2000} img1='/agent.jpg' img2='/agent2.jpg' img5='/agent3.jpg' img3='/agent4.jpg' img4='/agent5.jpg' img6='/agent6.jpg' widths='300px' heights='200px' fade_s={false} dots={false} />
                </div>
              </div>
              <h3 className="text-center font-bold">Agent App</h3>
            </div>
            <div className="flex flex-column">
              <div className="sm md:mx-auto sm:mx-auto  mx-auto out_side_mobile_view phonepic z_top">
                <div className="insde_mob_view">
                  <SliderComponent classvalue='mob_animate' speed_ns={2500} img1='/mob.jpg' img2='/agent.jpg' img5='/mob.jpg' img3='/mob2.jpg' img4='/agent2.jpg' img6='/agent3.jpg' widths='300px' heights='200px' fade_s={false} dots={false} />
                </div>
              </div>
              <h3 className="text-center font-bold">Employee App</h3>
            </div>
          </div>
        </div >
      </div >
    </>
  );
}

export default Phone;
