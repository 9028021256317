import React from 'react'
export default function Experience() {
    return (
        <div style={{
            background: '#e0e0e0',
            borderRadius: '22px',
            boxShadow: '20px 20px 60px #bebebe, -20px -20px 60px #ffffff',
            padding: '30px 30px',
        }} className='experience relative flex z-30 w-fit ml-auto justify-end shadow-2xl shadow-slate-900'>
            <div className="grid gap-2 grid-cols-4 ">
                <div style={{ borderTopLeftRadius: '35px' }} className="under_experience_card p-2 w-40 shadow-md flex justify-between items-center">
                    <div className="flex flex-col">
                        <h1 className='text-blue-800 text-2xl'>9001:2015</h1>
                        <p className='text-dark'>Years of Experience</p>
                    </div>
                    <div className="flex items-end h-100">
                        <img className='w-14'
                            src="/iso 9001-2015.png"
                            alt=""
                        />
                    </div>
                </div>
                <div className="under_experience_card p-2 w-36 shadow-md flex justify-between items-center">
                    <div className="flex flex-col">
                        <h1 className='text-blue-800 text-xl'>iso:90010</h1>
                        <p className='text-dark'>Expert Team</p>
                    </div>
                    <div className="flex items-end h-100">
                        <img className='w-14'
                            src="/iso 90010.jpg"
                            alt=""
                        />
                    </div>
                </div>
                <div className="col-span-2 flex under_experience_card p-2 shadow-md justify-between items-center">
                    <div className="flex flex-col">
                        <h3 className='text-blue-800 text-md'>Customer Choice</h3>
                        <p className='text-dark'>Satisfied Clients</p>
                    </div>
                    <div className="flex items-end h-100">
                        <img className='w-14'
                            src="/review-star.png"
                            alt="" />
                    </div>
                </div>
                <div className="col-span-2 flex under_experience_card p-2 shadow-md justify-between items-center">
                    <div className="flex flex-col">
                        <h1 className='text-blue-800 font-bold'>1000+</h1>
                        <p className='text-dark'>Active Software</p>
                    </div>
                    <div className="flex items-end h-100">
                        <img className='w-14'
                            src="/active_software.png"
                            alt=""
                        />
                    </div>
                </div>
                <div className="under_experience_card p-2 w-36 shadow-md flex justify-between items-center">
                    <div className="flex flex-col">
                        <h4 className='text-blue-800'>developers</h4>
                        {/* <p className='text-dark'>Clients Retention</p> */}
                        <img className='w-14'
                            src="/Software-developers-badge-2022.png"
                            alt="" />
                    </div>
                    <div className="flex items-end h-100">
                        
                    </div>
                </div>
                <div className="under_experience_card p-2 w-36 shadow-md flex justify-between items-center">
                    <div className="flex flex-col">
                        <h1 className='text-blue-800 font-bold'>5+</h1>
                        <p className='text-dark'>Star Rating</p>
                    </div>
                    <div className="flex items-end h-100">
                        <img className='w-14'
                            src="/5_star.png"
                            alt="" />
                    </div>
                </div>
                <div style={{ borderBottomLeftRadius: '55px' }} className="col-span-2 flex under_experience_card p-2 shadow-md justify-between items-center">
                    <div className="flex flex-col">
                        <h1 className='text-blue-800 font-bold'>150+</h1>
                        <p className='relative left-6 text-dark'>Business Partners</p>
                    </div>
                    <div className="flex items-end h-100">
                        <img className='w-14'
                            src="/business_partner.png"
                            alt=""
                        />
                    </div>
                </div>
                <div className="items hidden">
                    <p className='flex'>flex</p>
                </div>
                <div className="col-span-2 flex under_experience_card p-2 shadow-md justify-between items-center">
                    <div className="flex flex-col">
                        <p className=''>Awarded as</p>
                        <h2 style={{
                            fontSize: '25px',
                            width: '217px',
                        }} className='text-blue-800 font-bold'>Best Development Company</h2>
                    </div>
                    <div className="flex items-end h-100">
                        <img className='w-14'
                            src="/best_development_company.png"
                            alt=""
                        />
                    </div>
                </div>
            </div>
            <div style={{
                borderTopRightRadius: '15px',
                borderBottomRightRadius: '15px',
                height: '300px',
            }} className="relative w-64 mr-2 radius_full_at_934">
                <div style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/software.jpg)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    opacity: 0.5,
                    backgroundSize: 'cover',
                    borderTopRightRadius: '15px',
                    borderBottomRightRadius: '15px',
                }} className="p-2 w-64 h-100 top_left_bg_img  h-64 radius_full_at_934">
                </div>
                <div style={{
                    backgroundColor: 'rgb(18 55 171 / 70%)',
                    borderTopRightRadius: '15px',
                    borderBottomRightRadius: '15px',
                }} className="bgColor top-0 left-0 absolute w-full h-full z-20 radius_full_at_934"></div>
                <div style={{
                    opacity: 1,
                    top: '50%',
                    left: '50%',
                    zIndex: '50',
                    transform: 'translate(-50%, -50%)',
                    width:'256px',
                    borderTopRightRadius: '15px',
                    borderBottomRightRadius: '15px',
                }} className="text-center absolute w-64 top-2 left-3 my-0 radius_full_at_934 text-white">
                    <h1 className="text-center">Powered By</h1>
                    <img className='mx-auto block  w-24' src="/white_logo.png" alt="" />
                </div>
            </div>
        </div>
    )
}
