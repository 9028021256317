import React from 'react'
import '../Css/Special3.css';
import MySVGComponent from './MySvgComponent';

const Special3 = () => {
  return (
    <div className='spec3-conatiner'>
      <MySVGComponent/>
    </div>
  )
}

export default Special3