import React from 'react'

const TalkToExpertCard = ({imgSrc,headingText,bottomText}) => {
  return (
    <div className='shadow-2xl rounded flex flex-col items-center p-3 h-100 w-100'>
      <img className='h-16' src={imgSrc} alt="" />
      <h3 className='italic'>{headingText}</h3>
       {bottomText.map((htmlString, index) => (
        <div key={index} dangerouslySetInnerHTML={{ __html: htmlString }} />
      ))}
    </div>
  )
}
export default TalkToExpertCard
