import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import { GoChevronDown, GoChevronRight } from 'react-icons/go'; // Import required icons

const Support = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const accordionItems = [
    {
      title: 'I’m not sure my team will adopt it. What should I do?',
      content: 'We offer comprehensive onboarding and training sessions to ensure your team can smoothly integrate and use our solution. Please contact our support team to schedule a personalized training session.'
    },
    {
      title: 'How can I get help if I encounter an issue?',
      content: 'You can contact our support team via email, phone, or live chat. We also have a detailed FAQ and knowledge base available on our website to help you troubleshoot common issues.'
    },
    {
      title: 'Can I customize the product to fit my needs?',
      content: 'Yes, our product is highly customizable. We offer various customization options to tailor the solution to your specific requirements. Please reach out to our support team to discuss your needs in detail.'
    },
    {
      title: 'What is your refund policy?',
      content: 'We offer a 30-day money-back guarantee for all our products. If you’re not satisfied with your purchase within the first 30 days, you can request a full refund. For more details, please refer to our refund policy on the website.'
    },
    {
      title: 'Do you offer technical support?',
      content: 'Yes, we provide technical support as part of our service. Our support team is available to assist you with any technical issues you may encounter. Support is available via email, phone, and live chat during business hours.'
    },
    {
      title: 'How do I update my billing information?',
      content: 'To update your billing information, log in to your account on our website and navigate to the billing section. From there, you can update your payment details and review your billing history.'
    },
  ];
  


  
  return (
    <div className='support-container mb-8'>
      <h2 className='hehe'>Support</h2>
      <div className='d-flex justify-content-center mb-4'>
        <h1>Frequently Asked Questions</h1>
      </div>
      <h5 className='text-center  text-dark mb-2'>
        Got a question? We're here to answer! If you don't see your question here, drop us a line on our CONTACT PAGE.
      </h5>
      <div className='row items-center justify-items-center m-12'>
        <div className='col-md-5 m-6 '>
          {accordionItems.slice(0, 3).map((item, index) => (
            <div key={index} className='mb-3'>
              <div
                className='accordion-item'
                style={{ border: '2px solid pink', borderRadius: '1.25rem' }}
              >
                <button
                  className='accordion-header btn btn-light w-100 text-left d-flex justify-content-between align-items-center'
                  onClick={() => handleToggle(index)}
                  style={{ border: 'none', backgroundColor: '#f8f9fa' }}
                >
                  {item.title}
                  {openIndex === index ? (
                    <GoChevronDown style={{ fontSize: '2rem',borderradius :'1rem' }} />
                  ) : (
                    <GoChevronRight style={{ fontSize: '2rem',borderradius :'1rem' }} />
                  )}
                </button>
                {openIndex === index && (
                  <div
                    className='accordion-body'
                    style={{ padding: '2rem',borderradius :'1rem', }}
                  >
                    {item.content}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className='col-md-5 m-6'>
          {accordionItems.slice(3, 6).map((item, index) => (
            <div key={index + 3} className='mb-3'>
              <div
                className='accordion-item'
                style={{ border: '2px solid pink', borderRadius: '1.25rem' }}
              >
                <button
                  className='accordion-header btn btn-light w-100 text-left d-flex justify-content-between align-items-center'
                  onClick={() => handleToggle(index + 3)}
                  style={{ border: 'none', backgroundColor: '#f8f9fa' }}
                >
                  {item.title}
                  {openIndex === index + 3 ? (
                    <GoChevronDown style={{ fontSize: '2rem',borderradius :'1rem' }} />
                  ) : (
                    <GoChevronRight style={{ fontSize: '2rem',borderradius :'1rem' }} />
                  )}
                </button>
                {openIndex === index + 3 && (
                  <div
                    className='accordion-body'
                    style={{ padding: '2rem',borderradius :'1rem', backgroundColor: '#fff' }}
                  >
                    {item.content}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Support;
