import React from 'react';
import TalkToExpertCard from '../Components/TalkToExpertCard';


const TalkToExpert = () => {
    const bottomText1 = ['<p class="my-0 mt-3"><span style="color:grey">IND</span>-&nbsp;&nbsp; +91- 91539 88494</p>', '<p class="my-0"><span style="color:grey">IND</span>-&nbsp;&nbsp; +91- 91539 88493</p>', '<p class="my-0"><span style="color:grey">Email ID</span>-&nbsp;&nbsp; <a href="mailto:sales@idspay.in" style="font-weight:600">Sales@idspay.in</a></p>'];
    const bottomText2 = ['<p class="my-0 mt-3"><span style="color:grey">IND</span>-&nbsp;&nbsp; +91- 77820 05449</p>', '<p class="my-0"><span style="color:grey">IND</span>-&nbsp;&nbsp; +91- 86036 93666</p>', '<p class="my-0"><span style="color:grey">Email ID</span>-&nbsp;&nbsp; <a href="mailto:support@idspay.in" style="font-weight:600">support@idspay.in</a></p>'];
    const bottomText3 = ['<p class="my-0 mt-3"><span style="color:grey">Email ID</span>-&nbsp;&nbsp; <a href="mailto:api.support@infocartgroup.com" style="font-weight:600">api.support@infocartgroup.com</a></p>'];

    const bottomText4 = ['<p class="my-0 mt-3"><span style="color:grey">Email ID</span>-&nbsp;&nbsp; <a href="mailto:accounts@infocartgroup.com" style="font-weight:600">accounts@infocartgroup.com</a></p>'];

    const bottomText5 = ['<p class="my-0 mt-3"><span style="color:grey">IND</span>-&nbsp;&nbsp; +91- 91137 22202</p>', '<p class="my-0"><span style="color:grey">IND</span>-&nbsp;&nbsp; +91- 86036 93666</p>', '<p class="my-0 mt-3"><span style="color:grey">Email ID</span>-&nbsp;&nbsp; <a href="mailto:hr@infocartgroup.com" style="font-weight:600">hr@infocartgroup.com</a></p>'];

    const bottomText6 = ['<p class="my-0 mt-3"><span style="color:grey">Address</span>-&nbsp;&nbsp; First Floor, Pathak House, B-Block, Brahampura, Muzaffarpur- 842001</p>'];
    return (
        <>
            <div style={{marginTop:'9rem'}} className="row justify-center mx-3  mt-36">
                <div className="col-md-4">
                    <TalkToExpertCard headingText='Discuss for New Topics' imgSrc='/icon_phone.png' bottomText={bottomText1} />
                </div>
                <div className="col-md-4">
                    <TalkToExpertCard headingText='Support Department' imgSrc='/support.png' bottomText={bottomText2} />
                </div>
                <div className="col-md-4">
                    <TalkToExpertCard headingText='API Support Department' imgSrc='/api.png' bottomText={bottomText3} />
                </div>
                <div className="col-md-4">
                    <TalkToExpertCard headingText='Accounts Department' imgSrc='/accounting.png' bottomText={bottomText4} />
                </div>
                <div className="col-md-4">
                    <TalkToExpertCard headingText='HR Department' imgSrc='/support.png' bottomText={bottomText5} />
                </div>
                <div className="col-md-4">
                    <TalkToExpertCard headingText='Planning to visit office ?' imgSrc='/map2.png' bottomText={bottomText6} />
                </div>
            </div>
        </>
    );
};
export default TalkToExpert;