import React from 'react'
import LineDot from './LineDot';
import AwesomeCard from './AwesomeCard';

const SpecialNew = () => {
  return (
    <div className='container-special row mx-2'>
        <div style={{paddingRight:'5px'}} className='col-md-6'>
          <div className="inside h-100">
            
          <h1 className="spec-text" style={{fontSize:'2em',borderRadius:'5px', backgroundColor:'#0202a7',color:'white', width:'100%'}}>Technologies We Specialize In</h1>
          <div className="row mob_250 justify-content-center">
          <div className="col-md-4">
          <AwesomeCard img='css.png' text1='CSS 3' text2='CSS 3' text3='Development'/>
          </div>
          <div className="col-md-4">
          <AwesomeCard text1='HTML' img='html.png' text2='HTML 5' />
          </div>
          <div className="col-md-4">
          <AwesomeCard text1='JS' img='js.png' text2='JAVASCRIPT'/>
          </div>
          <div className="col-md-4">
          <AwesomeCard text1='React' img='react.png' text2='Reactjs' />
          </div>
          <div className="col-md-4">
          <AwesomeCard text1='Android' img='android.png' text2='Android Development' />
          </div>
          <div className="col-md-4">
          <AwesomeCard text1='SQL' img='sql.png' text2='SQL SERVER' />
          </div>
          <div className="col-md-4">
          <AwesomeCard text1='JAVA' img='java.png' text2='JAVA' />
          </div>
          <div className="col-md-4">
          <AwesomeCard text1='Laravel' img='laravel2.png' text2='Laravel' />
          </div>
          <div className="col-md-4">
          <AwesomeCard img='tailwind2.png' text2='Tailwind' text1='Tailwind' />
          </div>
          <div className="col-md-4">
          <AwesomeCard text1='Spring' img='spring2.png' text2='Spring Boot' />
          </div>
          <div className="col-md-4">
          <AwesomeCard text1='API' img='integration.png' text2='API Integration' />
          </div>
          <div className="col-md-4">
          <AwesomeCard img='jquery.png' text2='jQuery' text1='jQuery' />
          </div>
          <div className="col-md-4">
          <AwesomeCard img='nextjs.png' text2='NextJs' text1='NextJs' />
          </div>
          </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className="inside h-100">
          <h1 className="spec-text" style={{fontSize:'2em',borderRadius:'5px', backgroundColor:'#0202a7',color:'white', width:'100%'}}>Service Sector We Specialize In</h1>
          <div className="row mob_250 justify-content-center">
          <div className="col-md-4">
          <AwesomeCard text1='Banking' img='bank.png' text2='Banking Sector'/>
          </div>
          <div className="col-md-4">
          <AwesomeCard text1='Finance' img='loan.png' text2='Finance Sector' />
          </div>
          <div className="col-md-4">
          <AwesomeCard text1='Health' img='hospital.png' text2='Health Care Sector'/>
          </div>
          <div className="col-md-4">
          <AwesomeCard text1='NGO' img='building.png' text2='Non-Profit Organization'/>
          </div>
          <div className="col-md-4">
          <AwesomeCard text1='CRM' img='crm.png' text2='CRM & Employee Management'/>
          </div>
          <div className="col-md-4">
          <AwesomeCard text1='MLM' img='connection.png' text2='Network Marketting (MLM)' />
          </div>
          <div className="col-md-4">
          <AwesomeCard text1='Real Estate' img='estate.png' text2='Real E-State Sector' />
          </div>
          </div>
          </div>
        </div>
    </div>
  )
}
export default SpecialNew