import React from 'react'
import '../Css/SpecialNew.css';

export default function AwesomeCard({img,text1,text2,text3}) {
  const imageUrl = `/card images/${img}`;
  return (
    <>
    <div className="cardNew">
      <div className="border"></div>
      <div className="content">
        <div className="logo_">
          <div className="logo_1">
          <img src={imageUrl} alt={img} />
          <div className="top text-center">{text1}</div>
          </div>
          <div className="logo_2">
              {text2}
          </div>
          <span className="trail"></span>
        </div>
      </div>
      <span className="bottom-text">{text3}</span>
    </div>
    </>
  )
}
