import React from 'react';

export default function ProductsUndermobile() {
    return (
        <div style={{
            background: '#e0e0e0',
            borderRadius: '22px',
            boxShadow: '20px 20px 60px #bebebe, -20px -20px 60px #ffffff',
            padding: '30px 30px',
        }} className='relative full_radus_at744 flex column_at_744 z-30 my-3 w-fit gap-0 justify-start'>
            <div style={{
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px'
            }} className="relative w-64 mr-2 full_radus_at744">
                <div style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/hand_shake.jpg)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    opacity: 0.5,
                    backgroundSize: 'cover',
                    borderTopLeftRadius: '15px',
                    borderBottomLeftRadius: '15px'
                    
                }} className="p-2 w-64  top_left_bg_img  h-64 full_radus_at744">
                </div>
                <div style={{
                    backgroundColor: 'rgb(18 55 171 / 70%)',
                    width:'256px',
                    borderTopLeftRadius: '15px',
                    borderBottomLeftRadius: '15px'
                }} className="bgColor top-0 left-0 absolute w-full h-full z-20 full_radus_at744"></div>
                <p style={{
                    opacity: 1,
                    top: '50%',
                    left: '50%',
                    zIndex: '50',
                    transform: 'translate(-50%, -50%)'
                }} className="text-center absolute w-64 top-2 left-3 my-0 text-white">“We’re grateful for our growing community of partners who amplify our impact and help ensure that empathy and possibility replace labels and assumptions.” </p>
            </div>
            <div style={{ maxWidth: '788px' }} className="flex gap-1 center_at_744 flex-wrap justify-left">
                <div className="under_phone_card p-2 h-32 w-32 shadow-md flex justify-center items-center">
                    <img src="/company/razorpay.png" alt="" className="w-full" />
                </div>
                <div className="under_phone_card p-2 h-32 w-32 shadow-md flex justify-center items-center">
                    <img src="/company/indusind-bank1.png" alt="" className="w-full" />
                </div>
                <div className="under_phone_card p-2 h-32 w-32 shadow-md flex justify-center items-center">
                    <img src="/company/Icici Bank Logo.jpeg" alt="" className="w-full" />
                </div>
                <div className="under_phone_card p-2 h-32 w-32 shadow-md flex justify-center items-center">
                    <img src="/company/bbps.webp" alt="" className="w-full" />
                </div>
                <div className="under_phone_card p-2 h-32 w-32 shadow-md flex justify-center items-center">
                    <img src="/company/phonepay.svg" alt="" className="w-full" />
                </div>
                <div className="under_phone_card p-2 h-32 w-32 shadow-md flex justify-center items-center">
                    <img src="/company/cc_avenue.png" alt="" className="w-full" />
                </div>
                <div className="under_phone_card p-2 h-32 w-32 shadow-md flex justify-center items-center">
                    <img src="/company/yes_bank.png" alt="" className="w-full" />
                </div>
                <div className="under_phone_card p-2 h-32 w-32 shadow-md flex justify-center items-center">
                    <img src="/company/au_small_finance.webp" alt="" className="w-full" />
                </div>
                <div className="under_phone_card p-2 h-32 w-32 shadow-md flex justify-center items-center">
                    <img src="/company/nsdl.jpg" alt="" className="w-full" />
                </div>
                <div className="under_phone_card p-2 h-32 w-32 shadow-md flex justify-center items-center">
                    <img src="/company/paytm.avif" alt="" className="w-full" />
                </div>
                <div className="under_phone_card p-2 h-32 w-32 shadow-md flex justify-center items-center">
                    <img src="/company/idfc_first_bank.svg" alt="" className="w-full" />
                </div>
                <div className="under_phone_card p-2 h-32 w-32 shadow-md flex justify-center items-center">
                    <img src="/company/upi.png" alt="" className="w-full" />
                </div>
            </div>
        </div>
    )
}
