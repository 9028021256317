import React, { useState, useEffect } from 'react';
import { ReactTyped } from 'react-typed';

const TypingAnimation = ({ text }) => {
  const [loop, setLoop] = useState(true);

  const onComplete = (self) => {
    setTimeout(() => {
      self.reset();
    }, 6000);
  };
  return (
    <ReactTyped
      backSpeed={2}
      loop={loop}
      strings={[text]}
      typeSpeed={60}
    />
  );
};
export default TypingAnimation;