import React, { useEffect } from 'react';
import '../Css/Top.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'; // Import the ScrollTrigger plugin
import GoodButton from './GoodButton';
import { FaWhatsapp } from "react-icons/fa";
import TypingAnimation from './TypingAnimation';
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger); // Register the ScrollTrigger plugin with gsap

const Top = () => {
  useEffect(() => {
    const handleTopLoad = () => {
      gsap.from('.left-part h1', {
        opacity: 0,
        x: -50,
        duration: 1,
        delay: 0.5,
        scrollTrigger: {
          trigger: '.left-part h1',
          start: 'top 90%',
        },
      });

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.right-part',
          start: 'top 90%',
        },
      });

      tl.from('.right-part .toptext', { opacity: 0, y: 50, duration: 1, delay: 0.5 });
      tl.from('.right-part .topbtn', { opacity: 0, y: 50, duration: 1, delay: 0.2 });
    };

    // Add event listener for the load event
    // window.addEventListener('load', handleTopLoad);

    // // Cleanup function to remove the event listener
    // return () => {
    //   // Remove event listener when the component unmounts
    //   window.removeEventListener('load', handleTopLoad);
    // };
  }, []); // Empty dependency array to run the effect only once

  return (
    <div className="top-container flex justify-between">
      <div className="left-part">
        <h1 className='tracking-in-contract top-head __fast_type'>
<TypingAnimation text={'LOOKING FOR FREE SOFTWARE DEMO ?'}/></h1>

 
<p className='tracking-in-contract top-para'>We are ready to convert your idea in to real world.</p>
        {/* <img src="page.png" alt="corner" style={{zIndex:'-200000'}}/> */}
      </div>
      <div className="right-part">
      {/* <GoodButton text="Chat" classd="good_btn rounded-2xl">
      <FaWhatsapp />
        </GoodButton> */}
        <a target='_blank' href="https://api.whatsapp.com/send?phone=918603693666&text=%C2%A0Hello%C2%A0Sir">
        <button className='good_btn'>
  <span className="icon-text-1">
    <FaWhatsapp />
    <span>CHAT</span>
  </span>
</button></a>

<Link to='/TalkToExpert'><GoodButton text="Talk To Expert" classd="good_btn rounded-2xl ml-4"/></Link>
        
      </div>
      {/* <div className="right-part">
        
      </div> */}
    </div>
  );
};

export default Top;
