import React, { useState } from 'react';
import '../Css2/Contactus.css'; // Import your CSS file for Footer styling


const Contactus = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

    // Validate input
    if (name === 'name') {
      setErrors(prevState => ({
        ...prevState,
        name: value.length < 2 ? 'Name must be at least 2 characters long' : ''
      }));
    } else if (name === 'email') {
      setErrors(prevState => ({
        ...prevState,
        email: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? 'Invalid email address' : ''
      }));
    } else if (name === 'phone') {
      setErrors(prevState => ({
        ...prevState,
        phone: !/^\d{10}$/.test(value) ? 'Phone number must be 10 digits' : ''
      }));
    } else if (name === 'message') {
      setErrors(prevState => ({
        ...prevState,
        message: value.length < 10 ? 'Message must be at least 10 characters long' : ''
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, you can send formData to your backend or do any other processing here
    console.log(formData);
  };

  return (
    <>
      <h2 className='hehe'>Contact Us</h2>
      <div className='contact-us-container'>
        <img className='leag' src="bbb.svg" alt="Logo" />

        <div className='contact-content'>
          <form className='contact-form' onSubmit={handleSubmit}>
            <div className='form-group'>
              <label htmlFor='name'>Name</label>
              <input className='border-primary' type='text' id='name' name='name' value={formData.name} onChange={handleChange} />
              {errors.name && <span className='error'>{errors.name}</span>}
            </div>
            <div className='form-group'>
              <label htmlFor='email'>Email</label>
              <input className='border-primary' type='email' id='email' name='email' value={formData.email} onChange={handleChange} />
              {errors.email && <span className='error'>{errors.email}</span>}
            </div>
            <div className='form-group'>
              <label htmlFor='phone'>Phone</label>
              <input className='border-primary' type='tel' id='phone' name='phone' value={formData.phone} onChange={handleChange} />
              {errors.phone && <span className='error'>{errors.phone}</span>}
            </div>
            <div className='form-group'>
              <label htmlFor='message'>How can I help you?</label>
              <textarea className='border-primary' id='message' name='message' value={formData.message} onChange={handleChange} />
              {errors.message && <span className='error'>{errors.message}</span>}
            </div>
            <button className='btn btn-primary' type='submit'>Submit</button>
          </form>
          <div className='contact-info'>
            {/* Add contact information here if needed */}
            <div className='ci'>
              <h2>Phone</h2>
              <h2>Our customer care is open from Mon-Fri, 10:00 am to 6:00 pm</h2>
              <h2>(123) 456 7890</h2>
            </div>

            <div className='ci'>
              <h2>Phone</h2>
              <h2>Our customer care is open from Mon-Fri, 10:00 am to 6:00 pm</h2>
              <h2>(123) 456 7890</h2>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Contactus;