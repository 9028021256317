import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

// ProductCard Component
const ProductCard = ({ title, description, status }) => (
  <div style={styles.card}>
    <h3 style={styles.title}>{title}</h3>
    <p style={styles.description}>{description}</p>
    <span style={{ ...styles.status, color: status === 'Product is Live for Partner' ? 'green' : 'orange' }}>
      {status}
    </span>
  </div>
);

const styles = {
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    textAlign: 'center',
    transition: 'transform 0.3s, box-shadow 0.3s',
    border: '1px solid darkblue',
    boxSizing: 'border-box',
    width: '100%',
  },
  title: {
    margin: '0 0 10px',
  },
  description: {
    margin: '0 0 10px',
    color: '#666',
  },
  status: {
    display: 'block',
    fontWeight: 'bold',
  },
};

const Partners = () => {
  const products = [
    { title: 'Software For NGO', description: 'This software will easily manage your NGO/Non-Profit Organization. Core Features include Donation Management, Volunteer Management, Event Management, Profit & Loss, Staff Management, and more.',
       status: 'Product is Live for Partner' },



   { title: 'Employee Management Software', description: 'Download Lorem mobile banking app for IOS & Android to manage your online money.', status: 'Product is Live for Partner' },

  


    { title: 'Micro-Finance Software', description: 'Download Lorem mobile banking app for IOS & Android to manage your online money.', status: 'Product is Live for Partner' },


    { title: 'Co-Operative Society Software', description: 'Download Lorem mobile banking app for IOS & Android to manage your online money.',
       status: 'Product is Live for Partner' },


    { title: 'Nidhi Company Software', description: 'Download Lorem mobile banking app for IOS & Android to manage your online money.', status: 'Product is Live for Partner' },



    { title: 'E-Commerce Software', description: 'Download Lorem mobile banking app for IOS & Android to manage your online money.', status: 'Product is Live for Partner' },
    

      { title: 'Gold Loan Software', description: 'Download Lorem mobile banking app for IOS & Android to manage your online money.', 
      status: 'Product is Live for Partner' },

      { title: 'CRM Software', description: 'Download Lorem mobile banking app for IOS & Android to manage your online money.',
        status: 'Product is Live for Partner' },


        { title: 'Patho Lab Software', description: 'Download Lorem mobile banking app for IOS & Android to manage your online money.', status: 'Product is Live for Partner' },
  ];

  return (
    <div className='partner-container'>
      <h2 className='hehe'>Partners</h2>

      <div className='row mb-4'>
        <div className='col-md-6 mb-3'>
          <img
            src="hand_shake.jpg"
            alt="Logo"
            className='img-fluid rounded'
            style={{ border: '1rem solid #000' }}
          />
        </div>

        <div
          className='right-aboutus px-0'
          style={{
            borderRadius: '25px',
            background: 'white',
            padding: '20px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          }}
        >
          <h2 style={{ textAlign: 'center', color: 'blue', fontSize: '20px' }}>
            Join Our Partnership Program
          </h2>

          <form
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '15px',
              maxWidth: '100%',
              margin: '0 auto',
              gridTemplateAreas: `
                "company-name mobile"
                "email email"
                "address address"
                "submit submit"
              `,
              padding: '20px',
              borderRadius: '8px',
              backgroundColor: '#f9f9f9',
            }}
          >
            <div style={{ gridArea: 'company-name' }}>
              <label htmlFor="company-name">Company Name</label>
              <input
                id="company-name"
                type="text"
                style={{
                  padding: '10px',
                  borderRadius: '10px',
                  border: '1px solid #ccc',
                  width: '100%',
                  borderColor: 'blue',
                }}
                required
              />
            </div>
            <div style={{ gridArea: 'mobile' }}>
              <label htmlFor="mobile">Mobile Number</label>
              <input
                id="mobile"
                type="tel"
                style={{
                  padding: '10px',
                  borderRadius: '10px',
                  border: '1px solid #ccc',
                  width: '100%',
                  borderColor: 'blue',
                }}
                required
              />
            </div>
            <div style={{ gridArea: 'email' }}>
              <label htmlFor="email">Email Address</label>
              <input
                id="email"
                type="email"
                style={{
                  padding: '10px',
                  borderRadius: '10px',
                  border: '1px solid #ccc',
                  width: '100%',
                  borderColor: 'blue',
                }}
                required
              />
            </div>
            <div style={{ gridArea: 'address' }}>
              <label htmlFor="address">Address</label>
              <input
                id="address"
                type="text"
                style={{
                  padding: '10px',
                  borderRadius: '10px',
                  border: '1px solid #ccc',
                  width: '100%',
                  borderColor: 'blue',
                }}
                required
              />
            </div>
            <button
              type="submit"
              style={{
                gridArea: 'submit',
                backgroundColor: 'darkblue',
                color: 'white',
                padding: '10px',
                border: 'none',
                borderRadius: '10px',
                cursor: 'pointer',
                fontSize: '16px',
                width: '10rem',
                justifySelf: 'center',
              }}
            >
              Join Now
            </button>
          </form>
        </div>
      </div>

      <div className='row'>
        {products.map((product, index) => (
          <div className='col-lg-3 col-md-4 col-sm-6 mb-4' key={index}>
            <ProductCard
              title={product.title}
              description={product.description}
              status={product.status}
            />
          </div>
        ))}
      </div>

      <style jsx>{`
        @media (max-width: 576px) {
          .container {
            padding: 10px;
          }
          .row {
            margin: 0 -15px;
          }
          .col-lg-3, .col-md-4, .col-sm-6 {
            padding: 0 15px;
          }
        }
      `}</style>
      <style jsx>{`
        @media (max-width: 768px) {
          .partner-container {
            padding: 10px;
          }
          .aboutus-container1 {
            display: flex;
            flex-direction: column;
          }
          .left-aboutus, .right-aboutus {
            width: 100%;
          }
          .right-aboutus {
            padding: 10px;
          }
          form {
            grid-template-columns: 2fr;
            grid-template-areas: 
              "company-name"
              "mobile"
              "email"
              "address"
              "submit";
          }
          .product-container {
            display: block; // Make sure it's block for smaller screens
          }
        }
          
      `}</style>
    </div>
  );
};

export default Partners;