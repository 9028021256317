import React from 'react';
import '../Css/Special3.css';
import { FaArrowTrendUp } from "react-icons/fa6";

const MySVGComponent = () => {
  return (
    <div className="box_new_thing my-3 p-0">
      <div className="inside_box">
        <div className="d-flex justify-content-between">
        <div class="text_good">
  <div className="outer_p">
  <p>LATEST TECHNOLOGIES</p>
  </div>
</div>
        <div class="text_good">
          
  <div className="outer_p">
  <p>SMART COSTING</p>
  </div>
</div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
        <div class="text_good">
          <div className="outer_p">
            <p>WORK SATISFACTION</p>
          </div>
</div>
          <div className="relative flex h-full w-full items-center justify-center overflow-hiddens rounded-lg bg-background p-20">
            <img className='top_index' src={process.env.PUBLIC_URL + '/logo.png'} alt="" />
            <div className="line top left"></div>
            <div className="line between"></div>
            <div className="line top right"></div>
            <div className="absolute left-1/2 top-1/2 h-full w-full overflow-visible"><div className="absolute -translate-x-1/2 -translate-y-1/2 animate-ripple new_2 rounded-full bg-neutral-400"></div><div className="absolute -translate-x-1/2 -translate-y-1/2 animate-ripple new_3 rounded-full bg-neutral-400"></div><div className="absolute new_4 -translate-x-1/2 -translate-y-1/2 animate-ripple rounded-full bg-neutral-400"></div><div className="absolute new_5 -translate-x-1/2 -translate-y-1/2 animate-ripple rounded-full bg-neutral-400"></div><div className="absolute -translate-x-1/2 -translate-y-1/2 animate-ripple new_6 rounded-full bg-neutral-400"></div><div className="absolute -translate-x-1/2 -translate-y-1/2 animate-ripple new_7 rounded-full bg-neutral-400" ></div><div className="absolute -translate-x-1/2 -translate-y-1/2 new_8 animate-ripple rounded-full bg-neutral-400"></div><div className="absolute -translate-x-1/2 -translate-y-1/2 new_9 animate-ripple rounded-full bg-neutral-400"></div></div></div>
        <div class="text_good">
          
        <div className="outer_p">
  <p>SKILLED TEAM</p>
  </div>
</div>
        </div>
        <div className="d-flex justify-content-between">
        <div class="text_good">
        <div className="outer_p">
  <p>ON TIME DELIVERY</p>
  </div>
</div>
        <div class="text_good">
        <div className="outer_p">
  <p>QUALITY IS THE MAIN FOCUS</p>
  </div>
</div>
        </div>
      </div>
    </div>
  );
}

export default MySVGComponent;