// ScrollButton.js

import React from 'react';
import '../Css/ScrollButton.css';


const ScrollButton = () => {
  return (
    <button className="scroll-button"data-bs-toggle="modal" data-bs-target="#exampleModal">
      Schedule Demo.
    </button>
  );
};

export default ScrollButton;
